
<template>
  <div id="map">
    <v-row no-gutters>
      <v-col sm="12" style="position: relative;">
        <div class="buttons-area">
          <v-btn
            color="grey darken-1"
            class="white--text equalBtn"
            x-large
            depressed
            @click="etaToPickup()"
            >PICKUP ETA</v-btn
          >
          <v-btn
            color="orange darken-1"
            class="white--text equalBtn"
            x-large
            depressed
            @click="etaToDelivery()"
            >DELIVERY ETA</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <div class="eta-area">
      <v-row no-gutters v-show="visible_transit">
        <v-col sm="6" cols="6">
          Location
          <v-btn class="orange--text text--lighten-1" icon>
            <v-icon>mdi-arrow-right-bold</v-icon> </v-btn
          >Pickup
        </v-col>
        <v-col sm="6" cols="6">
          <div class="result">{{ transit }}</div>
        </v-col>
      </v-row>
      <v-row no-gutters v-show="visible_eta">
        <v-col sm="6" cols="6">
          Location
          <v-btn class="orange--text text--lighten-1" icon>
            <v-icon>mdi-arrow-right-bold</v-icon> </v-btn
          >Delivery
        </v-col>
        <v-col sm="6" cols="6">
          <div class="result">{{ eta }}</div>
        </v-col>
      </v-row>
      <v-row no-gutters v-show="visible_route">
        <v-col sm="6" cols="6">
          Pickup
          <v-btn class="orange--text text--lighten-1" icon>
            <v-icon>mdi-arrow-right-bold</v-icon> </v-btn
          >Delivery
        </v-col>
        <v-col sm="6" cols="6">
          <div class="result">{{ route }}</div>
        </v-col>
      </v-row>
    </div>    
    <div id="mapContainer" style="height:81.5vh;width:100%" ref="hereMap"></div>
    <v-layout row v-if="message.text">
      <v-flex xs12 sm6 px-1 ma-0>
        <app-alert
          :textAlert="message.text"
          :typeAlert="message.type"
          @dismissed="onDismissed()"
        ></app-alert>
      </v-flex>
    </v-layout>    
  </div>
</template>

<script>
export default {
  name: "HereMap",
  props: {
    center: Object
    // center object { lat: 40.730610, lng: -73.935242 }
  },
  computed: {
    message() {
      return this.$store.getters.message;
    }, 
    uom() {
      if (localStorage.getItem("maps_uom") !== null) {
        return localStorage.getItem("maps_uom");
      } else {
        return "KM";
      }
    },
    route_type() {
      if (localStorage.getItem("maps_type") !== null) {
        return localStorage.getItem("maps_type");
      } else {
        return "truck";
      }
    },  
    truck_restrictions() {
      if (localStorage.getItem("maps_truck_restrictions") !== null) {
        return localStorage.getItem("maps_truck_restrictions");
      } else {
        return "no";
      }
    },      
    transit() {
      var distance;
      if (this.transit_distance == 0) {
        return "...";
      } else {
        if (this.uom == "KM") {
          distance = this.transit_distance;
        } else {
          distance = (this.transit_distance * 0.62137).toFixed(1);
        }
        return (
          distance +
          " " +
          this.uom +
          " / ~ " +
          this.timeConvert(this.transit_time)
        );
      }
    },
    eta() {
      var distance;
      if (this.eta_distance == 0) {
        return "...";
      } else {
        if (this.uom == "KM") {
          distance = this.eta_distance;
        } else {
          distance = (this.eta_distance * 0.62137).toFixed(1);
        }
        return (
          distance + " " + this.uom + " / ~ " + this.timeConvert(this.eta_time)
        );
      }
    },
    route() {
      var distance;
      if (this.route_distance == 0) {
        return "...";
      } else {
        if (this.uom == "KM") {
          distance = this.route_distance;
        } else {
          distance = (this.route_distance * 0.62137).toFixed(1);
        }
        return (
          distance +
          " " +
          this.uom +
          " / ~ " +
          this.timeConvert(this.route_time)
        );
      }
    },
  },  
  data() {
    return {
      platform: null,
      apikey: "M167tCeLd-0fIEGf-rQat8jxa1wdEz8YIvOzUGxpREM",
      geocoder: {},
      routingService: {}, 
      map: null,
      transit_distance: 0,
      transit_time: 0,
      eta_distance: 0,
      eta_time: 0,
      route_distance: 0,
      route_time: 0,
      visible_transit: false,
      visible_eta: false,
      visible_route: false,      
    };
  },
  created() {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey
    });
    this.platform = platform;   
    this.geocoder = this.platform.getGeocodingService();
    this.routingService = this.platform.getRoutingService();
    this.onDismissed(); //clear all messages
  },
  async mounted() {     
    setTimeout(() => {
      this.transit_distance = localStorage.getItem("transit_distance");
      this.transit_time = localStorage.getItem("transit_time");
      this.eta_distance = localStorage.getItem("eta_distance");
      this.eta_time = localStorage.getItem("eta_time");
      this.route_distance = localStorage.getItem("route_distance");
      this.route_time = localStorage.getItem("route_time");
    }, 1500);      
    this.initializeHereMap();
  },
  methods: {     
    initializeHereMap() { // rendering map

      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      var maptypes = this.platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 10,
        center: this.center
        // center object { lat: 45.508, lng: -73.587 }
      });

      addEventListener("resize", () => this.map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

      // add UI
      H.ui.UI.createDefault(this.map, maptypes);
      // End rendering the initial map
    }, 
    setTruckingLayer(map, platform) {
      var mapTileService = platform.getMapTileService({
          type: 'base'
        });
      var parameters = {};
      var tileLayer = mapTileService.createTileLayer(
          'trucktile',
          'normal.day',
          256,
          'png8',
          parameters
        );
      map.setBaseLayer(tileLayer);
    },   
    drawRoute(start, finish) {
      const H = window.H;
      //const currentLocationIcon = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g><rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/></g><g><path id="svg_2" fill="#e74c3c" d="m12,0c-4.4183,0 -8,3.5817 -8,8c0,1.421 0.3816,2.75 1.0312,3.906c0.1079,0.192 0.221,0.381 0.3438,0.563l6.625,11.531l6.625,-11.531c0.102,-0.151 0.19,-0.311 0.281,-0.469l0.063,-0.094c0.649,-1.156 1.031,-2.485 1.031,-3.906c0,-4.4183 -3.582,-8 -8,-8zm0,4c2.209,0 4,1.7909 4,4c0,2.209 -1.791,4 -4,4c-2.2091,0 -4,-1.791 -4,-4c0,-2.2091 1.7909,-4 4,-4z"/><path id="svg_3" fill="#c0392b" d="m12,3c-2.7614,0 -5,2.2386 -5,5c0,2.761 2.2386,5 5,5c2.761,0 5,-2.239 5,-5c0,-2.7614 -2.239,-5 -5,-5zm0,2c1.657,0 3,1.3431 3,3s-1.343,3 -3,3s-3,-1.3431 -3,-3s1.343,-3 3,-3z"/></g></svg>'; 
      //const pickupLocationIcon = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g><rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/></g><g><path id="svg_2" fill="#00bf5f" d="m12,0c-4.4183,0 -8,3.5817 -8,8c0,1.421 0.3816,2.75 1.0312,3.906c0.1079,0.192 0.221,0.381 0.3438,0.563l6.625,11.531l6.625,-11.531c0.102,-0.151 0.19,-0.311 0.281,-0.469l0.063,-0.094c0.649,-1.156 1.031,-2.485 1.031,-3.906c0,-4.4183 -3.582,-8 -8,-8zm0,4c2.209,0 4,1.7909 4,4c0,2.209 -1.791,4 -4,4c-2.2091,0 -4,-1.791 -4,-4c0,-2.2091 1.7909,-4 4,-4z"/><path id="svg_3" fill="#007f3f" d="m12,3c-2.7614,0 -5,2.2386 -5,5c0,2.761 2.2386,5 5,5c2.761,0 5,-2.239 5,-5c0,-2.7614 -2.239,-5 -5,-5zm0,2c1.657,0 3,1.3431 3,3s-1.343,3 -3,3s-3,-1.3431 -3,-3s1.343,-3 3,-3z"/></g></svg>';    
      //const deliveryLocationIcon = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g><rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/></g><g><path id="svg_2" fill="#ff7f00" d="m12,0c-4.4183,0 -8,3.5817 -8,8c0,1.421 0.3816,2.75 1.0312,3.906c0.1079,0.192 0.221,0.381 0.3438,0.563l6.625,11.531l6.625,-11.531c0.102,-0.151 0.19,-0.311 0.281,-0.469l0.063,-0.094c0.649,-1.156 1.031,-2.485 1.031,-3.906c0,-4.4183 -3.582,-8 -8,-8zm0,4c2.209,0 4,1.7909 4,4c0,2.209 -1.791,4 -4,4c-2.2091,0 -4,-1.791 -4,-4c0,-2.2091 1.7909,-4 4,-4z"/><path id="svg_3" fill="#bf5f00" d="m12,3c-2.7614,0 -5,2.2386 -5,5c0,2.761 2.2386,5 5,5c2.761,0 5,-2.239 5,-5c0,-2.7614 -2.239,-5 -5,-5zm0,2c1.657,0 3,1.3431 3,3s-1.343,3 -3,3s-3,-1.3431 -3,-3s1.343,-3 3,-3z"/></g></svg>';
      var svgMarkup = '<svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
        '<rect stroke="#bbb" fill="#ffffff" x="1" y="1" width="22" height="22" />' +
        '<text x="12" y="18" font-size="13pt" font-family="Arial" font-weight="bold" ' +
        'text-anchor="middle" fill="${COLOR}">${TEXT}</text></svg>';
      var markers = [];      
      //check permission
      this.getPermissionStatus().then((permission) => {
        if (permission == "granted") {
          navigator.geolocation.getCurrentPosition((position) => {
            var icon_location = new H.map.Icon(svgMarkup.replace('${COLOR}', '#4A89F3').replace('${TEXT}', 'L'));
            var icon_pickup = new H.map.Icon(svgMarkup.replace('${COLOR}', '#007f3f').replace('${TEXT}', 'P'));
            var icon_delivery = new H.map.Icon(svgMarkup.replace('${COLOR}', '#fb8c00').replace('${TEXT}', 'D'));

            const currentLocationMarker = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            markers.push(new H.map.Marker(currentLocationMarker, {icon: icon_location}));
            markers.push(new H.map.Marker(start, {icon: icon_pickup}));
            markers.push(new H.map.Marker(finish, {icon: icon_delivery}));

            var params = {
                "mode": "fastest;" + this.route_type + ";traffic:enabled",
                "representation": "display",
                "routeAttributes": "summary",
                "waypoint0": currentLocationMarker.lat + "," + currentLocationMarker.lng,
                "waypoint1": start.lat + "," + start.lng,
                "waypoint2": finish.lat + "," + finish.lng          
            }

            this.map.removeObjects(this.map.getObjects());

            this.routingService.calculateRoute(params, data => {
              if(data.response) {
                  data = data.response.route[0];
                  var lineString = new H.geo.LineString();
                  data.shape.forEach(point => {
                    var parts = point.split(',');
                    lineString.pushLatLngAlt(parts[0], parts[1]);
                  });
                  var routeLine = new H.map.Polyline(lineString, {
                      style: { strokeColor: "#4A89F3", lineWidth: 5 }
                  });
                  this.map.addObjects([routeLine, ...markers]);
                  this.map.getViewPort().setPadding(50, 50, 50, 50);
                  this.map.getViewModel().setLookAtData({bounds: routeLine.getBoundingBox() });
                  if (this.truck_restrictions == "yes"){
                    this.setTruckingLayer(this.map, this.platform);
                  } 
                  console.log(data.summary);
              }
            }, error => {
                console.error(error);
            });               
          });       
        }
      });
    },  
    geocode(query) {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode({ searchText: query }, data => {
                if(data.Response.View[0].Result.length > 0) {
                    data = data.Response.View[0].Result.map(location => {
                        return {
                            lat: location.Location.DisplayPosition.Latitude,
                            lng: location.Location.DisplayPosition.Longitude
                        };
                    });
                    resolve(data);
                } else {
                    reject({ "message": "No data found" });
                }
            }, error => {
                reject(error);
            });
        });
    }, 
    etaToPickup() {

    },
    etaToDelivery() {

    },
    getPermissionStatus() {
      const permission = navigator.permissions
        .query({ name: "geolocation" })
        .then(function(result) {
          // Will return ['granted', 'prompt', 'denied']
          return result.state;
        });
      return permission;
    },    
    timeConvert(n) {
      var num = n;
      var hours = num / 60 / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if (rhours == 0) {
        return rminutes + " m";
      } else {
        return rhours + " h " + rminutes + " m";
      }
    },
    onDismissed() {
      this.$store.dispatch("clearMessage");
    },             
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  text-align: center;
  margin: 0px auto;
}
.buttons-area {
  position: relative;
  top: 2px;
  margin-bottom: 4px;
  width: 100%;
  height: 50px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.equalBtn {
  border-radius: 0px;
  width: 50%;
  height: -webkit-fill-available !important;
  font-size: 19px;
}
.eta-area {
  position: relative;
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 0px 15px;
  font-size: 1em;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.eta-area div.result {
  margin-top: 6px;
}
</style>